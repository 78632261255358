.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

/* .test{
  padding:0px;
}
@media screen and (max-width: 600){
.test{
    padding: 1000px!important;
    height: 1000px !important;
}
} */

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.mobile-padding{
  background-color: pink;
  height:0px;
  padding-top:20vh;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: black;
}

.App-header {
  background-color: pink;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;

  @media only screen and (max-width: 400){
    padding-top: 30% !important
  }
}

.App-header-mobile {
  background-color: pink;
  min-height: 80vh;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: black;

  @media only screen and (max-width: 400){
    padding-top: 30% !important
  }
}
